import z, {
  EnumLike,
  ZodDiscriminatedUnion,
  ZodIntersection,
  ZodObject,
  ZodType,
  ZodUnion,
  ZodUnionOptions,
} from 'zod';
import { DateTime } from 'luxon';

/**
 * @see https://github.com/colinhacks/zod/issues/1630
 */
export function coerceBoolean() {
  return z
    .union([z.boolean(), z.literal('true'), z.literal('false'), z.literal(''), z.undefined()])
    .transform((value) => value === true || value === 'true');
}

export function coerceTrue() {
  return z.union([z.boolean(), z.literal('true')]).transform((value) => value === true || value === 'true');
}

export function coerceFalse() {
  return z
    .union([z.literal('false'), z.literal(''), z.undefined()])
    .transform((value) => value === 'false' || value === '' || value === undefined);
}

export function zodJson<O extends ZodObject<any>>(schema: O) {
  return z.preprocess((v) => (typeof v === 'string' ? JSON.parse(v) : v), schema);
}

export function zodTPhone() {
  return z.coerce
    .string()
    .refine((v) => v.match(/\+1\d{10}/) !== null, 'Must be a valid phone number (starting with +1, eg: +19876543210).')
    .transform((v) => v as TPhone);
}

export function zodTEmailRequired() {
  return z
    .preprocess(
      (v) => (typeof v === 'string' ? v.trim().toLowerCase() : v),
      z.string({ required_error: 'Email is required.' }).email({ message: 'Email is invalid.' }),
    )
    .transform((v) => v as TEmail);
}

export function zodTEmailOptional() {
  return z
    .preprocess(
      (v) => (typeof v === 'string' ? v.trim().toLowerCase() : v),
      z.string({ required_error: 'Email is required.' }).email({ message: 'Email is invalid.' }),
    )
    .transform((v) => v as TEmail)
    .or(z.union([z.literal(''), z.undefined(), z.null()]).transform(() => undefined));
}

export function zodNativeEnum<T extends EnumLike>(values: T, optional = false) {
  const piped = z.coerce.string().min(1).pipe(z.nativeEnum(values));

  if (optional) {
    return piped.or(z.union([z.literal(''), z.undefined(), z.null()]).transform(() => undefined));
  }

  return piped;
}

export function zodTMoney() {
  return z.coerce
    .string()
    .refine(
      (v) => v.match(/^-?\d+(\.\d{1,2})?$/) !== null,
      'Must be a valid dollar amount (eg: 19.99) without a currency sign.',
    )
    .transform((v) => v as TMoney);
}

export function zodTMoneyOptional() {
  return z.coerce
    .string()
    .refine(
      (v) => v.match(/^-?\d+(\.\d{1,2})?$/) !== null,
      'Must be a valid dollar amount (eg: 19.99) without a currency sign.',
    )
    .transform((v) => v as TMoney).or(z.union([z.literal(''), z.undefined(), z.null()]).transform(() => undefined));
}

export function zodTDateIso() {
  return z
    .string()
    .refine((v) => {
      try {
        DateTime.fromISO(v);
        return true;
      } catch (e) {
        return false;
      }
    }, 'Must be a valid ISO date (YYYY-MM-DDTHH:mm:ss.SSSZ)')
    .transform((v) => v as TDateISO);
}

export function zodTDateISODate() {
  return z
    .string()
    .refine((v) => {
      try {
        DateTime.fromSQL(v);
        return true;
      } catch (e) {
        return false;
      }
    }, 'Must be a valid day format (YYYY-MM-DD)')
    .transform((v) => v as TDateISODate);
}

export function zodTTime24(error = 'Time is required.') {
  return z
    .string({ required_error: error })
    .refine((v) => {
      try {
        DateTime.fromFormat(v, 'HH:mm');
        return true;
      } catch (e) {
        return false;
      }
    }, 'Must be a valid 24-hour time (HH:mm)')
    .transform((v) => v as TTime24);
}

export function coerceIsoDate() {
  return z
    .string()
    .datetime({ offset: true })
    .transform((value) => new Date(value).toISOString());
}

/**
 * @see https://github.com/colinhacks/zod/issues/2461#issuecomment-1691426894
 */
export function coerceOptionalPositiveNumber() {
  return z
    .literal('')
    .transform(() => undefined)
    .or(z.coerce.number().min(1).optional());
}

export const FIRST_VERSION_TIMESTAMP = 1696464000000; // Date.UTC(2023, 9, 5);

export function schema_latest_version<S extends ZodVersionedSchema>(
  schemas: S,
  metadata?: ZodVersionedMetadata<S>,
): keyof typeof schemas & number {
  const now = Date.now();

  if (metadata?.version) {
    return metadata.version;
  }

  let target = FIRST_VERSION_TIMESTAMP;

  for (const key of Object.keys(schemas)) {
    const version = parseInt(key);

    if (version >= now) {
      target = version;
    }
  }

  return target as keyof typeof schemas & number;
}

export function coerceLiteralNumberRequired<T extends number>(value: T) {
  return z.preprocess((v) => (typeof v === 'string' ? parseInt(v) : v), z.literal(value)) as any as z.ZodLiteral<T>;
}

export function coerceLiteralNumberOptional<T extends number>(value: T) {
  return coerceLiteralNumberRequired(value).or(z.union([z.literal(''), z.undefined()]).transform(() => undefined));
}

type VersionedObject = ZodObject<
  { version: z.ZodLiteral<typeof FIRST_VERSION_TIMESTAMP> },
  'strip',
  z.ZodTypeAny,
  { version: typeof FIRST_VERSION_TIMESTAMP }
>;

type TypeObject = ZodObject<{ type: z.ZodLiteral<string> }, 'strip', z.ZodTypeAny, { type: string }>;

export type ZodVersionedSchema = Record<
  typeof FIRST_VERSION_TIMESTAMP,
  | VersionedObject
  | ZodIntersection<VersionedObject, ZodDiscriminatedUnion<'type', TypeObject[]>>
  | ZodIntersection<VersionedObject, ZodUnion<ZodUnionOptions>>
>;

export type ZodVersionedMetadata<Schema extends ZodVersionedSchema = ZodVersionedSchema> = {
  [Version in keyof Schema]: Schema[Version] extends ZodType ? z.TypeOf<Schema[Version]> : never;
}[keyof Schema];
